// Misc.
	$misc: (
		z-index-base:	10000
	);

// Duration.
	$duration: (
		header: 0.5s
	);

// Size.
	$size: (
		header: 275px
	);

// Font.
	$font: (
	);

// Palette.
	$palette: (
	);